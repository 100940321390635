<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit :form="accountNumber" ref="formEdit" v-if="!edit"></form-edit>
        <detail :id="id" :form="accountNumber" v-if="edit"></detail>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="12" lg="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click.stop="deleteDialog">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="accountNumber.accountNumber"
      title="Account Number"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
  </form>
</template>

<script>
import Detail from "./DetailAccountNumber";
import FormEdit from "./FormAccountNumber";
import DialogDelete from "@/components/DialogDelete";
import { mapState } from "vuex";

export default {
  name: "account-number-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
  }),
  computed: {
    ...mapState("accountNumber", ["accountNumber"]),
  },
  methods: {
    getAccountNumber() {
      this.$store.dispatch("accountNumber/getById", this.id);
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    deleteItem(id) {
      this.$store.dispatch("accountNumber/delete", id);
      this.dialog = false;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.getAccountNumber();
      this.edit = true;
    },
  },
  mounted() {
    this.getAccountNumber();
  },
};
</script>

<style></style>
